import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1> Search Anything, Know Everything!</h1>

        <h7> Welcome to Polaris - A modern conversational search engine</h7>
        <h5> Type in the search box to start searching </h5>
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
      <Sample />
    </div>
  );
}

function Sample() {
  return <h1>Develop. Preview. Ship.</h1>;
}

export default App;
